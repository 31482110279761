import {
  type ClientLoaderFunctionArgs,
  redirect,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useEffect, useState } from 'react';
import { useTitle } from 'react-use';

import { fetchAuthVerify } from '../../app/fetches/fetchAuthVerify';
import { useUserAnalytics } from '../analytics/user';
import { GuestLoginModal } from '../components/Access';
import { GuestQuickSignupModal } from '../components/Access/GuestQuickSignupModal';
import { GlobalLoading } from '../components/GlobalLoading';
import { ProvidersList } from '../components/ProvidersList';
import {
  useIsUserLoaded,
  UserContextProvider,
  useUser,
} from '../components/UserContext';
import { makeTitle } from '../utils/common';
import { getToken } from '../utils/getToken';
import { getKnownRedirectParam } from '../utils/redirect-to';
import { isUnauthenticated } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

function Bootstrap() {
  useUser({ init: true });
  const isUserLoaded = useIsUserLoaded();
  const navigate = useNavigate();
  const data = useLoaderData<typeof clientLoader>();

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (step === 1) {
      if (data.redirectTo) {
        window.location.href = data.redirectTo;
      } else {
        navigate('/');
      }
    }
  }, [data.redirectTo, navigate, step]);

  if (!isUserLoaded) return <GlobalLoading debug='register-into-org' />;

  return data.orgId ? (
    <GuestQuickSignupModal
      orgId={data.orgId}
      setStep={setStep}
      initialFirstName={data.name}
      initialEmail={data.email}
    />
  ) : (
    <GuestLoginModal
      conversion={false}
      setStep={setStep}
      initialFirstName={data.name}
    />
  );
}

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  const redirectTo = getKnownRedirectParam(url.searchParams, 'redirect-to');
  const orgId = getKnownRedirectParam(url.searchParams, 'org-id');
  const name = getKnownRedirectParam(url.searchParams, 'name');
  const email = getKnownRedirectParam(url.searchParams, 'email');

  try {
    // If we already have a user, redirect.
    await fetchAuthVerify(getToken());
    if (redirectTo) throw redirect(redirectTo);
  } catch (e) {
    if (!isUnauthenticated(e)) throw e;
  }

  return { redirectTo, orgId, name, email };
}

export function Component() {
  useTitle(makeTitle('Register into Organization'));

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Bootstrap />
    </ProvidersList>
  );
}
